import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['numInstances']

  connect() {
  }

  triggerUpdateEvent() {
    this.numInstancesTarget.dispatchEvent(new Event("change", { bubbles: true }));
  }

  plus(){
    this.numInstancesTarget.value = parseInt(this.numInstancesTarget.value) + 1;
    this.triggerUpdateEvent();
  }

  minus(){
    var numInstances = parseInt(this.numInstancesTarget.value);
    if (numInstances > 0) {
      this.numInstancesTarget.value = numInstances - 1;
      this.triggerUpdateEvent();
    }
  }
}